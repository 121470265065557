/* eslint semi: [0, 'always'] */
/* eslint one-var: 0 */
import {GA_ACTION_NAMES} from '../common/usage-tracking/google-analytics/google-analytics-action-names';

export const ENVIRONMENT = {
	DEV: 'dev',
	STG: 'stg',
	PROD: 'prod',
};

export const OPERATOR = {
	CONTAINS: {
		name: 'CONTAINS',
	},
	NOT_EQUAL_TO: {
		name: 'NOT_EQUAL_TO',
		isNegative: true,
	},
	IS_EQUAL_TO: {
		name: 'IS_EQUAL_TO',
	},
	DOES_NOT_END_WITH: {
		name: 'DOES_NOT_END_WITH',
		isNegative: true,
	},
	DOES_NOT_START_WITH: {
		name: 'DOES_NOT_START_WITH',
		isNegative: true,
	},
	ENDS_WITH: {
		name: 'ENDS_WITH',
	},
	STARTS_WITH: {
		name: 'STARTS_WITH',
	},
	DOES_NOT_CONTAIN: {
		name: 'DOES_NOT_CONTAIN',
	},
};

export const TRANE_DOMAINS = ['trane.com', 'tis-dev.net', 'tis-stg.net'];

angular
	.module('TISCC')
	.constant('API_ENDPOINTS', {
		tisExport: '/export',
		tisMetrics: '/metrics',
	})
	.constant('STATUS', {
		MODE: {
			'No data': {
				className: 'icon-question no-data-status',
				dirpageClassName: 'gray',
				title: 'No Test Result',
			},
			'Not Applicable': {
				className: 'invalid-application',
				dirpageClassName: 'gray',
				title: 'Not Applicable',
			},
			'Turning off': {
				className: 'icon-blocked2 no-data-status',
				dirpageClassName: 'gray',
				title: 'Suppressed',
			},
			'Invalid Data': {
				className: 'invalid-status',
				dirpageClassName: 'gray',
				title: 'Invalid Data',
			},
			'Insufficient Data': {
				className: 'insufficient-status',
				dirpageClassName: 'gray',
				title: 'Insufficient Data',
			},
			Green: {
				className: 'icon-normal normal-status',
				dirpageClassName: 'green',
				title: 'Normal',
			},
			Yellow: {
				className: 'icon-cautionary cautionary-status',
				dirpageClassName: 'yellow',
				title: 'Cautionary Level',
			},
			Red: {
				className: 'icon-critical critical-status',
				dirpageClassName: 'red',
				title: 'Critical Level',
			},
		},
	})
	.constant('FIXED_TREE_SORT', {
		COLUMN_NAME: 'groupName',
		// List tisEquipmentTypes which should be excluded from display
		COMPONENT_CRITERIA: ['Circuit', 'Compressor', 'Load Valve'],
	})
	.constant('LITERAL', {
		SPACE: ' ',
		SEMICOLON: ':',
		EXCEPTION: 'Exception',
		SUPPRESSION: 'Suppression',
		DATA_RANGE: 'DataRange',
		RED_LIMIT: 'RedLimit',
		LIMIT_EXTENTS: ['Upper', 'Lower'],
		LIMIT_TYPES: ['Red', 'Yellow'],
		LIMIT: 'Limit',
		BOUNDARY: 'Boundary',
		CONTROL_RANGE: 'Boundary',
		LOW: 'Low',
		HIGH: 'High',
		WEATHER_PREFIX: 'WeatherSourceV2::',
		WEATHER_PREFIX_OLD: 'WeatherSource::',
		AUTOMATED_TESTS_PREFIX: 'AUTOMATED_TEST_ACTIONS.',
		AUTOMATED_TESTS_THRESHOLDS_PREFIX: 'AUTOMATED_TEST_THRESHOLDS.',
		HPATH_PREFIXES: ['~', '^', '/child::', '//child:', '//parent::', '//', 'WeatherSourceV2::'],
	})
	.constant('WEATHER_PROPS_LIST', [
		{
			value: 'TEMP_REFERENCE_VALUE',
			propertyName: 'Temperature',
			sourcePrefix: 'WeatherSourceV2',
			needExtraFormatting: true,
			digitResolution: '0.1',
			typeName: null,
		},
		{
			value: 'HUMIDITY_REFERENCE_VALUE',
			propertyName: 'Humidity',
			sourcePrefix: 'WeatherSourceV2',
			typeName: null,
		},
		{
			value: 'WET_BULB_TEMP_REFERENCE_VALUE',
			propertyName: 'OutdoorAirWetBulbTemperature',
			sourcePrefix: 'WeatherSourceV2',
			needExtraFormatting: true,
			digitResolution: '0.1',
			typeName: null,
		},
		{
			value: 'WEATHER_STATION_ID',
			propertyName: 'WeatherStation',
			sourcePrefix: 'WeatherSourceV2',
			typeName: null,
		},
		{
			value: 'PROPERTIES.WeatherSourceV2::OutdoorAirEnthalpy',
			propertyName: 'OutdoorAirEnthalpy',
			sourcePrefix: 'WeatherSourceV2',
			typeName: null,
		},
	])
	.constant('CHART_TYPE', {
		BAR: 'stackedBar',
		LINE: 'line',
		LINE_WITH_BINARY_STATES: 'lineWithBinaryStates',
		LINE_WITH_CONTROL_RANGE_AND_MEAN: 'lineWithControlRangeAndMean',
		PARETO: 'pareto',
		PIE: 'pie',
		SCATTER: 'scatter',
		SCATTER_WITH_LIMITS: 'scatterWithLimits',
		SCATTER_WITH_PERFORMANCE_CURVE: 'scatterWithPerformanceCurve',
	})
	.constant('CHART_TYPE_LEVEL', {
		LOCATION: 'Location',
		TIS_OBJECT: 'TisObject',
	})
	.constant('CONTROLLER_CHART_TYPES', {
		EQUIPMENT_CHART: 'equipment',
		FACILITY_CHART: 'facility',
		PARETO_CHART: 'pareto',
	})
	.constant('DEFAULTS', {
		DATA_INTERVAL_MINUTES: 15,
		LOAD_BALANCER_COOKIE_NAME: 'tisexportforkid',
		PROD_MIN_ALLOWED_DATE: '01/01/2017',
	})
	.constant('CHART_DATE_FORMAT', {
		RANGE_DATE_FORMAT: 'MM-DD-YYYY',
	})
	.constant('AUTH_CONFIGURATION', {
		ROLE_LIST: {
			NONE: 'NONE',
			ALL: 'ALL',
			RDR_ONLY: 'RDR_ONLY',
			CPR_ONLY: 'CPR_ONLY',
		},
		ROLE_CONDITIONS: {
			RDR_ONLY: 'TISx.RDRonly',
			CPR_ONLY: 'TISx.CPRonly',
			TCC4Role: 'TIS.CommandCenter',
		},
		LOGIN_STATUSES: {
			AUTHORIZED: 'AUTHORIZED',
			NOT_AUTHORIZED: 'NOT_AUTHORIZED',
			AUTHENTICATION_REJECTED_NO_TOKEN: 'AUTH_NO_TOKEN',
			AUTHENTICATION_REJECTED_NO_PERMISSIONS: 'AUTH_REJECTED_NO_PERMISSIONS',
		},
		ROUTES: {
			LOGIN_URL: '/login',
			DEFAULT_FALLBACK_URL: '/facilities',
			RDRONLY_DEFAULT_MATCH: '/raw-data-report',
			RDRONLY_URL_MATCH: '/reports/raw-data',
			CPRONLY_URL_MATCH: '/reports/chiller-performance',
			CPRREPORT_URL_MATCH: '/chiller-performance-report',
		},
		AUTH_API_CONFIG: {
			ENDPOINTS: {
				USER_PERMISSIONS: '/ext_api/security-api/user',
				LOGIN: '/ext_api/security-api/login',
			},
			HEADERS: {
				TOKEN: 'token ',
				ACCEPT_APPLICATION_JSON: 'application/json',
				CONTENT_TYPE_FORM_ENCODED: 'application/x-www-form-urlencoded',
				USERNAME_STRING: 'username=',
				PASSWORD_STRING: '&password=',
			},
		},
		COOKIES: {
			COOKIE_NAME_40: '40CCSessionID',
			COOKIE_NAME_41: '41CCSessionID',
			COOKIE_DIVIDER: '=',
			COOKIE_DOMAINS: [...TRANE_DOMAINS, 'localhost'],
			COOKIE_EXPIRES: 'Thu, 01 Jan 1970 00:00:01 GMT',
		},
	})
	.constant('TRANE_DOMAINS', TRANE_DOMAINS)
	.constant('DESIGN_CURVES_COMPONENT_FILTER', {
		EVAPORATOR: 'evaporator',
		CONDENSER: 'condenser',
	})
	.constant('PAGE_TYPE', {
		CHART: 'chart',
		EQUIPMENT: 'equipment',
		LOCATION: 'facility',
	})
	.constant('ENVIRONMENT', ENVIRONMENT)
	.constant('EXPORT_CELL_COLOR', {
		GREEN: 'FF92D050',
		YELLOW: 'FFFFFF00',
		ORANGE: 'FFFFC000',
		GRAY: 'FFA5A5A5',
	})
	.constant('OPERATOR', OPERATOR)
	.constant('LINE_TYPE', {
		SOLID: 'solid',
		DASHED: 'dashed',
		DOTTED: 'dotted',
		DASH_DOT: 'dashdot',
		LONG_DASH: 'longdash',
	})
	.constant('LINE_THICKNESS', {
		SIZE_1: '1',
		SIZE_2: '3',
		SIZE_3: '6',
		SIZE_4: '9',
	})
	.constant('MARKER_TYPE', {
		DOT: {
			name: 'DOT',
			symbol: '&#9899;',
		},
		NONE: {
			name: 'NONE',
			symbol: '&nbsp;',
		},
		SQUARE: {
			name: 'SQUARE',
			symbol: '&#11035;',
		},
		TRIANGLE: {
			name: 'TRIANGLE',
			symbol: '&#9650;',
		},
		X: {
			name: 'X',
			symbol: '&#10060;',
		},
	})
	.constant('MARKER_SIZE', {
		SIZE_1: '3',
		SIZE_2: '6',
		SIZE_3: '9',
		SIZE_4: '12',
	})
	.constant('GA_ACTION_NAMES', GA_ACTION_NAMES)
	.constant(
		'PALETTE',
		'#61AE34,#FFF10B,#D52B1E,#00B9E4,#FB9A09,#DAFFA7,#FFF787,#FEA7AA,#CFD5E8,#FFEEC1,#B7FF5B,' +
			'#E1D47B,#FD575D,#6C97BF,#FEDE87,#89C506,#AB9A1F,#E10018,#326089,#FECF54'
	)
	.constant('GEN3_URL', {
		DEV: 'https://staging.mybuilding.trane.com/Ebiz/TIS2/TraneTISFlexWeb.aspx',
		STG: 'https://staging.mybuilding.trane.com/Ebiz/TIS2/TraneTISFlexWeb.aspx',
		PROD: 'https://mybuilding.trane.com/Ebiz/TIS2/TraneTISFlexWeb.aspx',
	})
	.constant('TC_URL', {
		DEV: 'https://gen4.connect.tis-dev.trane.com',
		STG: 'https://gen4.connect.tis-staging.trane.com',
		PROD: 'https://gen4.connect.tis.trane.com',
	})
	.constant('REPORT_TYPES', {
		ALARMS_AND_EVENTS_PDF: {
			report: 'alarms-and-events',
			directory: 'alarms-and-events',
			reportType: 'AlarmsAndEvents',
			name: 'ALARMS_AND_EVENTS',
			format: 'pdf',
			showInReportListDropDown: false,
		},
		ALARMS_AND_EVENTS: {
			report: 'alarms-and-events',
			directory: 'alarms-and-events',
			reportType: 'AlarmsAndEvents',
			name: 'ALARMS_AND_EVENTS',
			format: 'csv',
			showInReportListDropDown: true,
		},
		REPORT_CHILLER_PERFORMANCE_ENGLISH: {
			report: 'chiller-performance',
			directory: 'chiller-performance',
			name: 'CHILLER_PERFORMANCE',
			type: 'report',
			subtype: 'chiller-performance',
			format: 'pdf',
			showInReportListDropDown: true,
		},
		REPORT_DIGITAL_INSPECTION_ENGLISH: {
			report: 'digital-inspection',
			directory: 'digital-inspection',
			name: 'DIGITAL_INSPECTION',
			type: 'report',
			subtype: 'digital-inspection',
			format: 'pdf',
			showInReportListDropDown: false,
		},
		EXCEPTION_HISTORY: {
			report: 'exception-history',
			directory: 'exception-history',
			name: 'EXCEPTION_HISTORY',
			type: 'table',
			subtype: 'table',
			format: 'xlsx',
			showInReportListDropDown: true,
		},
		INTERNAL_INVENTORY: {
			report: 'internal-inventory',
			directory: 'inventory',
			reportType: 'InternalInventory',
			name: 'INTERNAL_INVENTORY',
			format: 'pdf',
			showInReportListDropDown: false,
		},
		INVENTORY: {
			report: 'inventory',
			directory: 'inventory',
			reportType: 'Inventory',
			name: 'INVENTORY',
			format: 'pdf',
			showInReportListDropDown: false,
		},
		RAW_DATA: {
			report: 'raw-data',
			directory: 'raw-data',
			name: 'RAW_DATA',
			type: 'table',
			subtype: 'table',
			format: 'xlsx',
			showInReportListDropDown: true,
		},
		SERVICE_ADVISORIES: {
			report: 'service-advisories',
			name: 'SERVICE_ADVISORIES',
			type: 'table',
			subtype: 'table',
			format: 'xlsx',
			showInReportListDropDown: false,
			downloadReport: 'DOWNLOAD_SA',
		},
		// Hide the "Overrides" report, since it's not needed anymore.
		// TODO: Remove it later.
		// OVERRIDES: {
		// 	report: 'overrides',
		// 	directory: 'overrides',
		// 	name: 'OVERRIDES',
		// 	type: 'report',
		// 	subtype: 'overrides',
		// 	format: 'pdf',
		// 	disabled: true,
		// 	showInReportListDropDown: true,
		// },
		FACILITY_DETAILS: {
			report: 'facility-details',
			name: 'REPORT_FACILITY_DETAILS',
			type: 'report',
			subtype: 'location-list',
			format: 'xlsx',
			showInReportListDropDown: false,
		},
		DIGITAL_INSPECTION: {
			report: 'digital-inspection',
			directory: 'digital-inspection',
			serverless: [ENVIRONMENT.DEV, ENVIRONMENT.STG, ENVIRONMENT.PROD],
			name: 'EQUIPMENT_MENU_DIGITAL_INSPECTION',
			type: 'automatedTest',
			subtype: 'digitalInspection',
			format: 'pdf',
			showInReportListDropDown: true,
		},
	})
	.constant('ROUTES', {
		EQUIPMENT_SUMMARY: '/facility/:locationId/equipment/:equipmentId/summary',
		FACILITY_SUMMARY: '/facility/:locationId',
	})
	.constant('CPR_REPORT_SECTIONS', {
		CHILLER_PROFILE: 'CHILLER_PROFILE',
		COMPRESSOR_RUN_HOURS_AND_STARTS: 'COMPRESSOR_RUN_HOURS_AND_STARTS',
		CHILLER_LIFE: 'CHILLER_LIFE',
		MILESTONES: 'MILESTONES',
		PERFORMANCE_IMPROVEMENTS: 'PERFORMANCE_IMPROVEMENTS',
		PERFORMANCE_ASSESSMENT: 'PERFORMANCE_ASSESSMENT',
		PLANT_LOAD_ANALYSIS: 'PLANT_LOAD_ANALYSIS',
		PERFORMANCE_EFFICIENCY: 'PERFORMANCE_EFFICIENCY',
		PERFORMANCE_WATER: 'PERFORMANCE_WATER',
		CONDENSER_HEAT_TRANSFER: 'CONDENSER_HEAT_TRANSFER',
		EVAPORATOR_HEAT_TRANSFER: 'EVAPORATOR_HEAT_TRANSFER',
		LOAD_PROFILE: 'LOAD_PROFILE',
		OPERATING_MODES: 'OPERATING_MODES',
		DIAGNOSTIC_CONDITIONS: 'DIAGNOSTIC_CONDITIONS',
		SHUTDOWNS: 'SHUTDOWNS',
		FAILED_SECTIONS: 'FAILED_SECTIONS',
	})
	.constant('VIEW_FILTERS', {
		ALL_FILTER: 'FILTER_ALL',
		ACTIVE_FILTER: 'FILTER_ACTIVE',
		DISABLED_FILTER: 'FILTER_DISABLED',
		MIXED_FILTER: 'FILTER_MIXED',
	})
	.constant('CPR_DETAILS_ACTION_TYPE', {
		IMPROVEMENT: 'Improvement',
		MILESTONE: 'Milestone',
	})
	.constant('AUTOMATED_TEST_LEVELS', {
		INDIVIDUAL: 'Individual',
		INTERMEDIATE: 'Intermediate',
		COMBINED: 'Combined',
		ADDONEXCEPTION: 'AddonException',
	})
	.constant('DIR_STATUS', {
		'No Data': {
			className: 'icon-question no-data-status',
			verticalBar: 'vertical-no-data-status',
			resultText: 'Tests could not complete',
		},
		Green: {
			className: 'icon-normal normal-status',
			verticalBar: 'vertical-green-status',
			resultText: 'All tests passed the specified criteria',
		},
		Yellow: {
			className: 'icon-cautionary cautionary-status',
			verticalBar: 'vertical-yellow-status',
			resultText: 'Tests in Warning state',
		},
		Red: {
			className: 'icon-critical critical-status',
			verticalBar: 'vertical-red-status',
			resultText: 'Tests Require Further Investigation',
		},
	})
	.constant('OFFERINGS', ['BP', 'CAP', 'DI-CH']);
